import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Space,
  Image,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  CalendarOutlined,
  ManOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import FileUpload from "../../../../components/InputComponents/FileUpload";
import Styles from "./ShareholderFormNext.module.css";
import Cross from "../../../../assests/customIcons/Cross";
import countries from "../../Data/countries.json";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../../../constant/ApplicationRoutes";
import { noSpecialCharactersValidator } from "../../../../utils/helpers";
const { Option } = Select;

const ShareholderForm = ({ visible, onClose, onSubmit, shareholder }: any) => {
  const [form] = Form.useForm();
  const [commercialBusinessLicenseBack, setCommercialBusinessLicenseBack] =
    useState<any>([]);
  const [commercialBusinessLicenseFront, setCommercialBusinessLicenseFront] =
    useState<any>([]);

  useEffect(() => {
    setCommercialBusinessLicenseFront(
      shareholder?.national_id_front_side || []
    );
    setCommercialBusinessLicenseBack(shareholder?.national_id_back_side || []);
  }, [shareholder, form]);

  const initialValues = {
    ...shareholder,

    contactPersonPhone: {
      countryCode: shareholder?.contactPersonPhone?.countryCode || "+973",
      number: shareholder?.contactPersonPhone?.number || "",
    },
  };

  const debounce = (func: any, delay: any) => {
    let debounceTimer: any;
    return function (this: any) {
      const context: any = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const [emailExists, setEmailExists] = useState(false);

  const isValidEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = debounce(async (e: any) => {
    const email = e.target.value;
    if (email && isValidEmail(email)) {
      try {
        const response = await axios.post(
          `${BASE_URL}checkUserExists/${email}`
        );
        if (response.data.status) {
          setEmailExists(true);
        } else {
          setEmailExists(false);
        }
      } catch (error) {
        console.error("Error checking email", error);
        setEmailExists(false);
      }
    }
  }, 500);

  const handleFinish = async (values: any) => {
    const { countryCode, number } = values.contactPersonPhone || {};
    const formattedPhone = {
      number: number || "",
      countryCode: countryCode || "+973",
    };
    const formattedValues = {
      ...values,
      contactPersonPhone: formattedPhone,
      national_id_front_side: commercialBusinessLicenseFront,
      national_id_back_side: commercialBusinessLicenseBack,
    };
    await onSubmit(formattedValues);
    onClose();
    form.resetFields();
  };

  const updateBusinessDocs = (data: any, docType: string) => {
    if (docType === "front") {
      const index: any = Object.keys(data)[0];

      const value = data[index];
      let updatedImages = [...commercialBusinessLicenseFront];

      if (value) {
        updatedImages.push(value);
        updatedImages = [updatedImages[updatedImages.length - 1]];
      } else {
        updatedImages.splice(index, 1);
      }

      setCommercialBusinessLicenseFront(updatedImages);

      form.setFieldsValue({
        national_id_front_side: updatedImages,
      });
    } else if (docType === "back") {
      const index: any = Object.keys(data)[0];

      const value = data[index];
      let updatedImages = [...commercialBusinessLicenseBack];

      if (value) {
        updatedImages.push(value);
        updatedImages = [updatedImages[updatedImages.length - 1]];
      } else {
        updatedImages.splice(index, 1);
      }

      setCommercialBusinessLicenseBack(updatedImages);

      form.setFieldsValue({
        national_id_back_side: updatedImages[updatedImages.length - 1],
      });
    }
  };

  return (
    <Drawer
      title={shareholder ? "Edit Shareholder" : "Add Shareholder"}
      placement="right"
      closable={true}
      closeIcon={
        <Space style={{ zIndex: 100, marginRight: "1.5rem" }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
      onClose={onClose}
      open={visible}
      width={"95%"}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input the shareholder name!",
                },
                {
                  max: 25,
                  message: "First name cannot exceed 25 characters",
                },
                {
                  validator: noSpecialCharactersValidator(
                    "No special characters are allowed!"
                  ),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Enter shareholder name"
                className={Styles.FormInput}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input a valid email!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                type="email"
                placeholder="Enter shareholder email"
                className={Styles.FormInput}
                onChange={handleEmailChange}
              />
              {emailExists && (
                <p
                  style={{ color: "red" }}
                >{`User already exists with this email. \n Please use another email.`}</p>
              )}
            </Form.Item>

            <Form.Item name="contactPersonBirthdate" label="Birthdate">
              <DatePicker
                style={{ width: "100%" }}
                className={Styles.FormInput}
                disabledDate={(current: any) => {
                  const minDate = moment().subtract(100, "years");
                  const maxDate = moment().subtract(17, "years");
                  return current && (current > maxDate || current < minDate);
                }}
              />
            </Form.Item>
            <Form.Item
              name="contactPersonCountry"
              label="Country"
              rules={[{ required: true, message: "Please select a country!" }]}
            >
              <Select
                showSearch
                placeholder="Select your country"
                filterOption={(input: any, option: any) => {
                  const country: any = countries.find(
                    (country) => country.code === option.value
                  );
                  return country.name
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                className={Styles.FormInput}
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.code}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={country.flag}
                        alt=""
                        style={{ marginRight: 8, width: 20, height: 20 }}
                      />
                      {country.name}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contactPersonLastName"
              label="Last Name"
              rules={[
                { required: true, message: "Please input the last name!" },
                {
                  max: 25,
                  message: "Last name cannot exceed 25 characters",
                },
                {
                  validator: noSpecialCharactersValidator(
                    "No special characters are allowed!"
                  ),
                },
              ]}
            >
              <Input
                placeholder="Enter last name"
                className={Styles.FormInput}
              />
            </Form.Item>

            <Form.Item
              name="contactPersonPhone"
              label="Phone"
              rules={[
                { required: true, message: "Please input the phone number!" },
              ]}
            >
              <Input.Group
                compact
                style={{ width: "100%" }}
                className={Styles.FormInput}
              >
                <Select
                  defaultValue={
                    shareholder?.contactPersonPhone?.countryCode || "+973"
                  }
                  style={{ width: "20%" }}
                  onChange={(countryCode) =>
                    form.setFieldsValue({
                      contactPersonPhone: {
                        ...form.getFieldValue("contactPersonPhone"),
                        countryCode,
                      },
                    })
                  }
                  showSearch
                  optionFilterProp="children"
                  className={Styles.FormInputNumber}
                >
                  {countries.map((country) => (
                    <Option key={country.code} value={country.dial_code}>
                      <img
                        src={country.flag}
                        alt={country.name}
                        style={{ marginRight: 8, width: 20 }}
                      />
                      {country.dial_code}
                    </Option>
                  ))}
                </Select>
                <Input
                  style={{ width: "80%" }}
                  onChange={(e) =>
                    form.setFieldsValue({
                      contactPersonPhone: {
                        ...form.getFieldValue("contactPersonPhone"),
                        number: e.target.value,
                      },
                    })
                  }
                  placeholder="Enter phone number"
                  className={Styles.FormInputNumber}
                  defaultValue={shareholder?.contactPersonPhone?.number}
                />
              </Input.Group>
            </Form.Item>

            <Form.Item name="contactPersonGender" label="Gender">
              <Select
                placeholder="Select a gender"
                className={Styles.FormInput}
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="contactPersonNationality"
              label="Nationality"
              rules={[
                { required: true, message: "Please select a nationality" },
              ]}
            >
              <Select
                showSearch
                placeholder="Select your nationality"
                filterOption={(input: any, option: any) => {
                  const country: any = countries.find(
                    (country) => country.code === option.value
                  );
                  return country.name
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                className={Styles.FormInput}
              >
                {countries.map((country) => (
                  <Option key={country.code} value={country.code}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={country.flag}
                        alt=""
                        style={{ marginRight: 8, width: 20, height: 20 }}
                      />
                      {country.name}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            {shareholder &&
            shareholder.national_id_front_side.length > 0 &&
            commercialBusinessLicenseFront.length > 0 ? (
              <div className={Styles.imageContainer}>
                {(commercialBusinessLicenseFront.length > 0
                  ? commercialBusinessLicenseFront
                  : shareholder.national_id_front_side
                ).map((file: any, index: any) => (
                  <div key={index} className={Styles.imageWrapper}>
                    <p
                      className={Styles.imageTitle}
                    >{`National ID Front Side`}</p>
                    <>
                      {file.type.includes("image") ? (
                        <Image
                          src={URL.createObjectURL(file)}
                          alt={`National ID Back Side ${index + 1}`}
                          className={Styles.image}
                        />
                      ) : (
                        <iframe
                          src={URL.createObjectURL(file)}
                          className={Styles.image}
                        />
                      )}
                    </>

                    <Button
                      className={Styles.removeButton}
                      onClick={() => {
                        setCommercialBusinessLicenseFront([]);
                      }}
                      icon={<Cross />}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <FileUpload
                title1="Drag & drop or Upload your National ID copy (Front Side)"
                description="Jpeg, png, pdf file with max size of 10mb."
                setData={(data: any) => updateBusinessDocs(data, "front")}
                name="national_id_front_side"
                uploadMultiple={false}
                alllowAll
              />
            )}
          </Col>

          <Col span={12}>
            {shareholder &&
            shareholder.national_id_back_side.length > 0 &&
            commercialBusinessLicenseBack.length > 0 ? (
              <div className={Styles.imageContainer}>
                {(commercialBusinessLicenseBack.length > 0
                  ? commercialBusinessLicenseBack
                  : shareholder.national_id_back_side
                ).map((file: any, index: any) => (
                  <div key={index} className={Styles.imageWrapper}>
                    <p
                      className={Styles.imageTitle}
                    >{`National ID Back Side`}</p>
                    <>
                      {file.type.includes("image") ? (
                        <Image
                          src={URL.createObjectURL(file)}
                          alt={`National ID Back Side ${index + 1}`}
                          className={Styles.image}
                        />
                      ) : (
                        <iframe
                          src={URL.createObjectURL(file)}
                          className={Styles.image}
                        />
                      )}
                    </>

                    <Button
                      className={Styles.removeButton}
                      onClick={() => {
                        setCommercialBusinessLicenseBack([]);
                      }}
                      icon={<Cross />}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <FileUpload
                title1="Drag & drop or Upload your National ID copy (Back Side)"
                description="Jpeg, png, pdf file with max size of 10mb."
                setData={(data: any) => updateBusinessDocs(data, "back")}
                name="national_id_back_side"
                uploadMultiple={false}
                alllowAll
              />
            )}
          </Col>
        </Row>
        <Col span={24}>
          <Button htmlType="submit" className={Styles.numberAdd}>
            {shareholder ? "Update Shareholder" : "Add Shareholder"}
          </Button>
        </Col>
      </Form>
    </Drawer>
  );
};

export default ShareholderForm;
