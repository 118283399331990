import { Button, Card, Carousel, Col, QRCode, Row, notification } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useSearch } from "../../../common/DashboardNavBar/Search/SearchContext";
import Styles from "./DashboardPage.module.css";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import BackgroundWhiteBox from "../../../common/BackgroundWhiteBox/BackgroundWhiteBox";
import TopCard from "../../../common/Cards/TopCard/TopCard";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";

import { _GET, _POST, getRole, getUserId } from "../../../common/commonFunc";
import MapPreview from "../../Map/MapPreview";
import { ArrowBackIosNew, Bookmark, BookmarkBorder } from "@mui/icons-material";
import Stories from "react-insta-stories";
import { Modal, Typography } from "@mui/material";
import Cross from "../../../assests/customIcons/Cross";

import ServiceCardHome from "../../../common/Cards/ServiceCard/ServiceCardHome";
import EventCardHome from "../../../common/Cards/EventCard/EventCardHome";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import StoryModal from "./Listingcards/StoryModal";
import { useDispatch, useSelector } from "react-redux";
import GenericPage from "../../SupplierScreens/Profile/GenericPage";
import GenericPageForCards from "../../SupplierScreens/Profile/GenericPageForCards";
import LikeButton from "../../../assets/SVG/OctopusLikeButton.svg";
import Comment from "../../../assests/customIcons/Comment";
import Share from "../../../assests/customIcons/Share";
import { RWebShare } from "react-web-share";
import { CommentsDrawer } from "./Listingcards/CommentsDrawer";
import LoginPopUp from "../../Login/LoginPopup";
import PostDrawer from "./PostDrawer";
import { getFreelancerProfile } from "../../../redux/actions/FreelancerProfile";
import { Helmet } from "react-helmet-async";

const DashboardPage: React.FC = () => {
  const [providers, setProviders] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState(providers);
  const [filteredSuppliers, setFilteredSuppliers] = useState(suppliers);
  const [coords, setCoords] = useState<any>(null);
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [postData, setPostData] = useState([]);
  const [events, setEvents] = useState([]);
  const [activeUserStories, setActiveUserStories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUserIndex, setCurrentUserIndex] = useState(0);
  const [categoriesData, setCategoriesData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [storyModalOpen, setStoryModalOpen] = useState(false);
  const [storiesData, setStoriesData] = useState<any>([]);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openDrawer, setopenDrawer] = useState<any>(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const { searchQuery, filters } = useSearch();
  const token = localStorage.getItem("jwtToken");

  const [open, setOpen] = useState(false);
  const scrollRef = useRef<any>(null);
  const scrollRefSupplier = useRef<any>(null);
  const scrollRefStory = useRef<any>(null);
  const scrollRefServices = useRef<any>(null);
  const scrollRefEvents = useRef<any>(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const scrollLeftSupplier = () => {
    if (scrollRefSupplier.current) {
      scrollRefSupplier.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRightSupplier = () => {
    if (scrollRefSupplier.current) {
      scrollRefSupplier.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const scrollLeftStory = () => {
    if (scrollRefStory.current) {
      scrollRefStory.current.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  const scrollRightStory = () => {
    if (scrollRefStory.current) {
      scrollRefStory.current.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  const scrollLeftServices = () => {
    if (scrollRefServices.current) {
      scrollRefServices.current.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  const scrollRightServices = () => {
    if (scrollRefServices.current) {
      scrollRefServices.current.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  const scrollLeftEvents = () => {
    if (scrollRefEvents.current) {
      scrollRefEvents.current.scrollBy({ left: -400, behavior: "smooth" });
    }
  };

  const scrollRightEvents = () => {
    if (scrollRefEvents.current) {
      scrollRefEvents.current.scrollBy({ left: 400, behavior: "smooth" });
    }
  };

  const onFreelancerProfileClickHandler = async (post: any) => {
    await dispatch(
      getFreelancerProfile(_.get(post.post, "userId", ""), (value: any) => {
        setopenDrawer(value);
      })
    );
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setFilteredServices(services);
    setFilteredProviders(providers);
    setFilteredSuppliers(suppliers);
  }, [providers, suppliers, services]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleRemoveService = (serviceId: any) => {
    const updatedServices = services.filter(
      (service: any) => service.id !== serviceId
    );
    setServices(updatedServices);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleCloseStory1 = () => {
    setIsModalVisible(false);
    setActiveUserStories([]);
  };

  const openStoryModal = () => {
    setStoryModalOpen(true);
  };

  const handleStoryOk = () => {
    setStoryModalOpen(false);
  };

  const closeStoryModal = () => {
    setStoryModalOpen(false);
  };
  const providerNames = [
    ...new Set(
      services.map(
        (service: any) =>
          service.service_provider_details?.first_name || "Unknown"
      )
    ),
  ];

  const providerNamesCard = providers.map(
    (provider: any) => provider.first_name
  );
  const supplierNames = suppliers.map((supplier: any) => supplier.first_name);

  const filterTypes: any = {
    category: {
      label: "Category",
      data: [
        ...new Set(
          categoriesData.map((category: any) => category.service_category_main)
        ),
      ],
    },
    provider: {
      label: "Provider",
      data: providerNames,
    },
    providerTop: {
      label: "Top Providers",
      data: providerNamesCard,
    },
    supplier: {
      label: "Supplier",
      data: supplierNames,
    },
  };

  const filterTypesArray = Object.keys(filterTypes).map((key) => ({
    type: key,
    label: filterTypes[key].label,
    options: filterTypes[key].data,
  }));

  useEffect(() => {
    let newFilteredServices = services.filter((service: any) => {
      const matchesSearch =
        service &&
        service?.service_name &&
        service?.service_name.toLowerCase().includes(searchQuery.toLowerCase());

      return (
        matchesSearch &&
        filters.every((filter) => {
          switch (filter.type) {
            case "category":
              return service.service_category_main === filter.value;
            case "price":
              return service.service_fees <= filter.value;
            case "provider":
              return (
                service.service_provider_details?.first_name === filter.value
              );
            default:
              return true;
          }
        })
      );
    });

    let newFilteredProviders = providers.filter((provider: any) => {
      const matchesSearch = provider?.first_name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return (
        filters.every((filter) => {
          switch (filter.type) {
            case "providerTop":
              return provider.first_name === filter.value;
            default:
              return true;
          }
        }) && matchesSearch
      );
    });

    let newFilteredSuppliers = suppliers.filter((supplier: any) => {
      const matchesSearch =
        searchQuery &&
        supplier?.first_name.toLowerCase().includes(searchQuery?.toLowerCase());

      const filtered = filters.every((filter) => {
        switch (filter.type) {
          case "supplier":
            return supplier.first_name === filter.value;
          default:
            return true;
        }
      });
      return filtered && matchesSearch;
    });

    if (newFilteredServices.length > 0) {
      setFilteredServices(newFilteredServices);
    }
    if (newFilteredProviders.length > 0) {
      setFilteredProviders(newFilteredProviders);
    }
    if (newFilteredSuppliers.length > 0) {
      setFilteredSuppliers(newFilteredSuppliers);
    }
  }, [searchQuery, filters, services, providers, suppliers]);

  const suggestions = ["/provider:", "/supplier:"];

  function transformArrayToObject(array: any) {
    const transformedObject: any = [];

    array.forEach((item: any, index: any) => {
      const userDetails = item?.postUserDetails;
      const key = `${userDetails?.first_name} ${userDetails?.last_name}`;
      const storyId = item?.post?._id;
      const value = {
        header: {
          heading: `${userDetails?.first_name} ${userDetails?.last_name}`,
          subheading: "Posted 2h ago",
          profileImage: userDetails?.profile_pic,
        },

        content: ({ action, isPaused }: any, header: any) => (
          <div
            style={{
              background: "lightblue",

              height: "100%",
              textAlign: "center",
              width: "100%",
              paddingTop: "100px",
            }}
            className="main"
          >
            {renderFile(item?.post?.imageUrls[0], index)}
            <div
              style={{ position: "absolute", top: 20, left: 40, zIndex: 999 }}
            >
              {item?.post &&
                item?.post?.overlays &&
                item?.post?.overlays.map((overlay: any, overlayIndex: any) => (
                  <img
                    key={overlayIndex}
                    src={overlay?.link}
                    alt={`Overlay ${overlayIndex + 1}`}
                    style={{
                      height: "20px",
                      width: "20px",
                      position: "absolute",
                      top: `${overlay.position.y}%`,
                      left: `${overlay.position.x}%`,
                      transform: `scale(${overlay.position.scale}) rotate(${overlay.position.rotation}deg)`,
                    }}
                  />
                ))}
            </div>
          </div>
        ),
        duration: 8000,

        storuId: storyId,
        displayname: item?.postUserDetails?.display_name || "",
      };

      if (!transformedObject[key]) {
        transformedObject[key] = [];
      }

      transformedObject[key].push(value);
    });

    return transformedObject;
  }

  const handleProfileClick = async (userName: any) => {
    const currentStories = storiesData[userName] || [];
    console.log("➡️   ~ handleProfileClick ~ currentStories:", currentStories);

    if (currentStories.length > 0) {
      const currentStory = currentStories[0];
      const storyId = currentStory?.storuId;

      setActiveUserStories(currentStories);
      setCurrentUserIndex(Object.keys(storiesData).indexOf(userName));
      setIsModalVisible(true);

      try {
        const body = {
          storyIds: [storyId],
        };

        const response = await _POST(`social/seen/story`, body, true, true);
        console.log("response in user seen story", response);

        if (response.statusCode === 200) {
          getAllPosts();
        }
      } catch (error) {
        console.error("error in story seen API", error);
      }
    }
  };

  const handleCloseStory = () => {
    console.log("Closed");
    setActiveUserStories([]);
    if (Object.keys(storiesData).length > currentUserIndex + 1) {
      const nextUser = Object.keys(storiesData)[currentUserIndex + 1];
      handleProfileClick(nextUser);
    } else {
      setIsModalVisible(false);
    }
  };

  const getAllServiceProviders = async () => {
    let response;
    response = await _GET("getAllServiceProviderList/", false, false);
    if (response) {
      if (response && response.data) {
        const sortedProviders = response.data.sort(
          (a: any, b: any) => b.user_rating - a.user_rating
        );
        setProviders(sortedProviders);
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Please reload and try again to get providers.",
        });
      }
    }
  };
  const getAllStories = async () => {
    if (userRole !== "guest" && token) {
      let response;
      response = await _GET(
        `social/user/fetch/stories?userId=${userId}`,
        true,
        false
      );
      if (response) {
        if (response.data && response.data.length > 0) {
          let storyResponse = await transformArrayToObject(response.data);
          setStoriesData(storyResponse);
        }
      }
    } else {
      let response;
      response = await _GET(`social/open/fetch/stories`, false, false);
      if (response) {
        if (response.data && response.data.length > 0) {
          let storyResponse = await transformArrayToObject(response.data);
        }
      }
    }
  };

  const getAllSuppliers = async () => {
    let response;
    response = await _GET("getAllSuppliersList/", false, false);
    if (response) {
      if (response && response.data) {
        const sortedSuppliers = response.data.sort(
          (a: any, b: any) => b.user_rating - a.user_rating
        );
        setSuppliers(sortedSuppliers);
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Please reload and try again to get providers.",
        });
      }
    }
  };

  const getAllServices = async () => {
    let response = await _GET("services/getAllServices", true, false);

    if (response) {
      if (response.data) {
        setServices(response.data);
      }
    }
  };

  useEffect(() => {}, [services, coords]);

  const getAllServicesEvents = async () => {
    try {
      const response = await _GET("services/getAllEvents", false, false);
      if (response && response.data) {
        setEvents(response.data);
      }
    } catch (error) {
      notification.open({
        type: "error",
        message: "Error",
        description: "An error occurred while fetching services.",
      });
    }
  };

  const getAllCategoriesData = async () => {
    let response;

    response = await _GET("services/getAllCategoriesData", false, false);

    if (response) {
      if (response.data) {
        setCategoriesData(response.data);
        const categories = response.data.map(
          (dataObj: any) => dataObj.service_category_main
        );
        const uniqueCategories: any = [...new Set(categories)];
        uniqueCategories.unshift("All");
        setAllCategories(uniqueCategories);
      }
    }
  };

  useEffect(() => {
    getAllServiceProviders();
    getAllSuppliers();
    getAllServices();
    getAllServicesEvents();
    getAllCategoriesData();
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
      }
    );

    if (coords === null) {
      setCoords({ lat: 25.276762, lng: 55.299276 });
    }
  }, []);
  useEffect(() => {
    getAllServiceProviders();
    getAllSuppliers();
    getAllServices();
    getAllServicesEvents();
    getAllCategoriesData();
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
      }
    );

    if (coords === null) {
      setCoords({ lat: 25.276762, lng: 55.299276 });
    }
  }, []);
  const [userRole, setUserrole] = useState(getRole());
  const [userId, setUserId] = useState(getUserId());
  useEffect(() => {
    const userrole: any = getRole();
    const userid: any = getUserId();
    setUserId(userid);
    setUserrole(userrole);
  });

  const [postLoading, setPostLoading] = useState(false);

  const getAllPosts = async () => {
    setPostLoading(true);
    if (userRole !== "guest") {
      let response;
      response = await _GET(`social/user/fetch/post`, true, true);
      if (response) {
        if (response.data) {
          setPostData(response?.data);
          setPostLoading(false);
          if (selectedPost && selectedPost.post && selectedPost.post._id) {
            const updatedPost = response.data.find(
              (post: any) => post.post._id === selectedPost.post._id
            );
            setSelectedPost(updatedPost || null);
          }
        } else {
          return notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please reload and try again to get posts.",
          });
        }
      }
    } else {
      const response = await _GET("social/open/fetch/post", false, false);
      if (response) {
        if (response?.data) {
          setPostData(response?.data);
          setPostLoading(false);
          if (selectedPost && selectedPost.post && selectedPost.post._id) {
            const updatedPost = response.data.find(
              (post: any) => post.post._id === selectedPost.post._id
            );
            setSelectedPost(updatedPost || null);
          }
        } else {
          return notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please reload and try again to get posts.",
          });
        }
      }
    }
  };
  useEffect(() => {
    getAllPosts();
    getAllStories();
  }, []);

  const LikePost = async () => {
    if (token) {
      const response = await _POST(
        "social/provide/octopus",
        { postId: selectedPost?.post?._id, userId: userId },
        false
      );
      if (response) {
        notification.open({
          type: "success",
          message: "Post Liked",
          description: "Post has been liked",
        });
        await getAllPosts();
      } else {
        notification.open({
          type: "error",
          message: "Post not Liked",
          description: "Post was not liked",
        });
      }
    } else {
      handleOpenLoginPopup();
    }
  };

  const UnlikePost = async () => {
    if (token) {
      const response = await _POST(
        "social/revert/octopus",
        { postId: selectedPost?.post?._id, userId: userId },
        false
      );
      if (response) {
        notification.open({
          type: "success",
          message: "Post UnLiked",
          description: "Post has been unliked",
        });
        await getAllPosts();
      } else {
        notification.open({
          type: "error",
          message: "Post not Unliked",
          description: "Post was not unliked",
        });
      }
    } else {
      handleOpenLoginPopup();
    }
  };

  const handleSavedItems = async () => {
    if (token) {
      const payload = {
        itemType: "post",
        itemId: selectedPost?.post?._id,
      };

      try {
        const postData = await _POST("userProfile/saveItem", payload, true);
        if (postData) {
          notification.open({
            type: "success",
            message: "Post Saved Successfully",
            description: "This Post has been saved successfully.",
          });
          await getAllPosts();
        } else {
          throw new Error("Failed to save the post");
        }
      } catch (error) {
        notification.open({
          type: "error",
          message: "Error Saving Post",
          description: "There was an error saving the post.",
        });
      }
    } else {
      handleOpenLoginPopup();
    }
  };

  function renderFile(url: string, index: number) {
    const extension = url.split(".").pop()?.toLowerCase() || "";

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

    const videoExtensions = ["mp4"];

    if (imageExtensions.includes(extension)) {
      return <img key={index} src={url} alt="Uploaded file" />;
    }

    if (videoExtensions.includes(extension)) {
      return (
        <video key={index} src={url} autoPlay={true} muted={false}></video>
      );
    }
  }

  function stripHtml(html: string) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const closePostDrawer = () => {
    setDrawerVisible(false);
    setSelectedPost(null);
  };

  const renderPostCard = (post: any) => {
    const renderMedia = () => {
      return (
        <Carousel
          className={Styles.carousel}
          effect="scrollx"
          arrows={post?.post?.imageUrls?.length > 1}
          dots={false}
          prevArrow={
            <button
              className={Styles.navButton}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
              }}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={Styles.svgIcon}
              >
                <path
                  d="M14.9991 19L9.83911 14C9.56672 13.7429 9.34974 13.433 9.20142 13.0891C9.0531 12.7452 8.97656 12.3745 8.97656 12C8.97656 11.6255 9.0531 11.2548 9.20142 10.9109C9.34974 10.567 9.56672 10.2571 9.83911 10L14.9991 5"
                  stroke="#30263e"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          }
          nextArrow={
            <button
              className={Styles.navButton}
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={Styles.svgIcon}
              >
                <path
                  d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19"
                  stroke="#30263e"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          }
        >
          {post?.post?.imageUrls?.map((url: string, index: number) => {
            const isVideo =
              url.endsWith(".mp4") ||
              url.endsWith(".webm") ||
              url.endsWith(".ogg");
            return (
              <div key={index} onClick={(e: any) => openPostDrawer(post)}>
                {isVideo ? (
                  <video controls className={Styles.coverImage} height="100%">
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    alt={post?.post?.caption ?? "Image"}
                    src={url}
                    className={Styles.coverImage}
                  />
                )}
              </div>
            );
          })}
        </Carousel>
      );
    };

    const postSeenHandler = async () => {
      console.log("postSeenHandler");
      try {
        const postId = post?.post?._id;

        const body = {
          postIds: [postId],
        };

        const response = await _POST(`social/seen/post`, body, true, true);

        console.log("response in user seen post", response);
        if (response.statusCode === 200) {
          getAllPosts();
          setSelectedPost((prevPost: any) => {
            if (prevPost && prevPost.post) {
              return {
                ...prevPost,
                post: {
                  ...prevPost.post,
                  totalPostView: (prevPost.post.totalPostView || 0) + 1,
                },
              };
            } else {
              return prevPost;
            }
          });
        }
      } catch (error) {
        console.error("error in post seen api", error);
      }
    };

    const isValidValue = (value: any) => {
      return (
        value &&
        value !== "NULL" &&
        value !== "null" &&
        value !== null &&
        value !== undefined &&
        value.trim() !== ""
      );
    };

    const renderProfileInfo = () => {
      const profilePic = isValidValue(post?.postUserDetails?.profile_pic)
        ? post.postUserDetails?.profile_pic
        : null;
      let displayName = isValidValue(post?.postUserDetails?.display_name)
        ? post.postUserDetails.display_name
        : `${post?.postUserDetails?.first_name ?? ""} ${
            post?.postUserDetails?.last_name ?? ""
          }`.trim();

      if (displayName.length > 7) {
        displayName = displayName.substring(0, 7) + "...";
      }

      const formatDate = (dateString: any) => {
        const date: any = new Date(dateString);
        const now: any = new Date();
        const differenceInMinutes = Math.floor((now - date) / (1000 * 60));

        if (differenceInMinutes < 60) {
          return `${differenceInMinutes} minutes ago`;
        } else if (differenceInMinutes < 1440) {
          const hours = Math.floor(differenceInMinutes / 60);
          return `${hours} hour${hours > 1 ? "s" : ""} ago`;
        } else if (differenceInMinutes < 10080) {
          const days = Math.floor(differenceInMinutes / 1440);
          return `${days} day${days > 1 ? "s" : ""} ago`;
        } else {
          return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          });
        }
      };

      const postDate = formatDate(post?.post?.createdAt);

      return (
        <div className={Styles.profileInfoContainer}>
          <div
            className={Styles.profileDetails}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedPost(post);
              onFreelancerProfileClickHandler(post);
            }}
          >
            {profilePic ? (
              <img
                src={profilePic}
                alt={displayName}
                className={Styles.profilePic}
              />
            ) : (
              <div className={Styles.profilePicPlaceholder}>
                {displayName.charAt(0).toUpperCase()}
              </div>
            )}
            <div className={Styles.profileName} title={displayName}>
              {displayName}
            </div>
          </div>
          <div className={Styles.postDate}>{postDate}</div>
        </div>
      );
    };

    const renderProfileInfoSave = () => {
      const profilePic = isValidValue(post?.postUserDetails?.profile_pic)
        ? post.postUserDetails?.profile_pic
        : null;
      const displayName = isValidValue(post?.postUserDetails?.display_name)
        ? post.postUserDetails.display_name
        : `${post?.postUserDetails?.first_name ?? ""} ${
            post?.postUserDetails?.last_name ?? ""
          }`.trim();

      return (
        <div className={Styles.profileInfoContainer}>
          <div
            className={Styles.saveButton}
            onClick={(e) => {
              e.stopPropagation();
              handleSavedItems();
            }}
          >
            {post?.postUserDetails?.issaved ? <Bookmark /> : <BookmarkBorder />}
          </div>
        </div>
      );
    };

    const plainTextDesc = stripHtml(post?.post?.caption);
    const shareText = `Checkout My New Post:\n Post Name: ${
      post?.post?.caption
    }\n ${plainTextDesc.slice(0, 100)}\n Link:\n`;

    const renderIcons = () => {
      const isLiked = post?.post?.isLikedByUser;

      const handleLikeButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (token) {
          isLiked ? UnlikePost() : LikePost();
        } else {
          handleOpenLoginPopup();
        }
      };

      const handleCommentButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (token) {
          setSelectedPost(post);
          onOpen();
        } else {
          setSelectedPost(post);
          onOpen();
        }
      };

      return (
        <div className={Styles.iconContainer}>
          <button onClick={handleLikeButtonClick} className={Styles.iconBtn}>
            <img
              src={LikeButton}
              alt="Like"
              className={Styles.icon}
              style={{
                filter: isLiked ? "grayscale(0%)" : "grayscale(100%)",
                transition: "filter 0.5s",
              }}
            />
          </button>

          <button className={Styles.iconBtn} onClick={handleCommentButtonClick}>
            <Comment />
            <div className={Styles.countOnIcon}>
              {post?.post?.comments?.length ?? 0}
            </div>
          </button>
          <RWebShare
            data={{
              text: shareText,
              url: "https://testing.theoctopus.ai/",
              title: post?.post?.caption,
            }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Share />
            </div>
          </RWebShare>
        </div>
      );
    };

    const LikePost = async () => {
      const response = await _POST(
        "social/provide/octopus",
        { postId: post?.post?._id, userId: userId },
        false
      );
      if (response) {
        notification.open({
          type: "success",
          message: "Post Liked",
          description: "Post has been liked",
        });
        await getAllPosts();
      } else {
        notification.open({
          type: "error",
          message: "Post not Liked",
          description: "Post was not liked",
        });
      }
    };

    const UnlikePost = async () => {
      const response = await _POST(
        "social/revert/octopus",
        { postId: post?.post?._id, userId: userId },
        false
      );
      if (response) {
        notification.open({
          type: "success",
          message: "Post UnLiked",
          description: "Post has been unliked",
        });
        await getAllPosts();
      } else {
        notification.open({
          type: "error",
          message: "Post not Unliked",
          description: "Post was not unliked",
        });
      }
    };

    const handleSavedItems = async () => {
      if (token) {
        const payload = {
          itemType: "post",
          itemId: post?.post?._id,
        };

        try {
          const postData = await _POST("userProfile/saveItem", payload, true);
          if (postData) {
            notification.open({
              type: "success",
              message: "Post Saved Successfully",
              description: "This Post has been saved successfully.",
            });
            await getAllPosts();
          } else {
            throw new Error("Failed to save the post");
          }
        } catch (error) {
          notification.open({
            type: "error",
            message: "Error Saving Post",
            description: "There was an error saving the post.",
          });
        }
      } else {
        handleOpenLoginPopup();
      }
    };

    const openPostDrawer = (post: any) => {
      setSelectedPost(post);
      setDrawerVisible(true);
      postSeenHandler();
    };

    return (
      <>
        <Card
          loading={postLoading}
          className={Styles.cardWrapper}
          cover={
            <>
              <div className={Styles.cardImageWrapper}>
                {renderMedia()}
                <div className={Styles.rightOverlay}>
                  {renderProfileInfoSave()}
                  <div className={Styles.bottomIconsContainer}>
                    {renderIcons()}
                    {renderProfileInfo()}
                  </div>
                </div>
              </div>
            </>
          }
        >
          <Card.Meta
            description={
              <div className={Styles.cardContent}>
                <div className={Styles.cardBottomContent}>
                  <div className={Styles.serviceDesc}>
                    {post?.post?.likes?.length ?? 0} Likes -{" "}
                    {post?.post?.totalPostView ?? 0} Views <br />
                    <span> {post?.post?.caption ?? "No caption provided"}</span>
                  </div>
                </div>
              </div>
            }
          />
        </Card>
      </>
    );
  };

  const checkOverflow = () => {
    const el = scrollRefStory.current;
    console.log("➡️   ~ checkOverflow ~ el:", el);
    if (el) {
      setIsOverflowing(el.scrollWidth > el.clientWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
    const observer = new MutationObserver(() => {
      checkOverflow();
    });

    if (scrollRefStory.current) {
      observer.observe(scrollRefStory.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => observer.disconnect();
  }, []);

  const pageTitle = "Dashboard";
  const pageDescription =
    "Explore the latest services and stories on our platform.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar
          filterTypes={filterTypesArray}
          showFilters={showFilters}
          toggleFilters={toggleFilters}
          suggestions={suggestions}
        />

        <Row className={Styles.dashboardContainerRow}>
          <Col span={6} className={Styles.mobileHide}>
            <div className={Styles.dashboardSection1}>
              <MapPreview coords={coords} services={services} />
              <BackgroundWhiteBox title="Blogs" seeAllUpper={true}>
                <div className={Styles.blogRow}>
                  {/* {blogList.map((blogItem) => (
                    <BlogCard blogItem={blogItem} key={blogItem.id} />
                  ))} */}
                  <p>No Blogs Posted</p>
                </div>
              </BackgroundWhiteBox>
            </div>
          </Col>
          <Col span={12} className={Styles.mobileVisible}>
            <div className={Styles.dashboardMidSection}>
              <div className={Styles.storiesContainer}>
                {isOverflowing && (
                  <button
                    className={Styles.leftButtonStory}
                    onClick={scrollLeftStory}
                  >
                    <ArrowBackIosNew fontSize="small" />
                  </button>
                )}
                <div className={Styles.storiesRow} ref={scrollRefStory}>
                  {activeUserStories.length === 0 ? (
                    <div
                      className="profile-images"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 10,
                      }}
                    >
                      {userRole === "freelancer" && (
                        <svg
                          onClick={() => openStoryModal()}
                          width="90"
                          height="90"
                          cursor="pointer"
                          viewBox="0 0 72 72"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            opacity="0.2"
                            cx="36"
                            cy="36"
                            r="35"
                            stroke="#30263E"
                            stroke-linecap="round"
                            stroke-dasharray="2 6"
                          />
                          <circle
                            opacity="0.05"
                            cx="36"
                            cy="36"
                            r="30"
                            fill="#30263E"
                          />
                          <path
                            d="M35.168 35.167V30.167H36.8346V35.167H41.8346V36.8337H36.8346V41.8337H35.168V36.8337H30.168V35.167H35.168Z"
                            fill="#30263E"
                          />
                        </svg>
                      )}

                      {Object.keys(storiesData).map((userName, index) => {
                        const currentUserStory =
                          storiesData[userName] && storiesData[userName][0];
                        const profileImage =
                          currentUserStory?.header?.profileImage;
                        const currentUserDisplay =
                          currentUserStory?.displayname !== ""
                            ? currentUserStory?.displayname
                            : userName;

                        return (
                          <div className={Styles.storyContainer}>
                            <div
                              className="storyContainer"
                              style={{
                                height: "90px",
                                width: "90px",
                                background:
                                  "linear-gradient(45deg, #3498db, #bb86fc)",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}
                              key={index}
                            >
                              {profileImage ? (
                                <img
                                  key={userName}
                                  src={profileImage}
                                  alt={userName}
                                  onClick={() => handleProfileClick(userName)}
                                  style={{
                                    borderRadius: "50%",
                                    width: "80px",
                                    height: "80px",
                                    border: "5px solid white",
                                  }}
                                />
                              ) : (
                                <div
                                  onClick={() => handleProfileClick(userName)}
                                  style={{
                                    borderRadius: "50%",
                                    width: "80px",
                                    height: "80px",
                                    background:
                                      "linear-gradient(45deg, #3498db, #bb86fc)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "white",
                                    fontSize: "36px",
                                    fontWeight: "bold",
                                    border: "5px solid white",
                                  }}
                                >
                                  {userName.charAt(0).toUpperCase()}
                                </div>
                              )}
                            </div>
                            <p>{currentUserDisplay}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <Modal open={isModalVisible} onClose={handleCloseModal}>
                      <>
                        <div className={Styles.App}>
                          <div className={Styles.stories}>
                            <Stories
                              loop
                              keyboardNavigation
                              stories={activeUserStories}
                              onAllStoriesEnd={handleCloseStory}
                              defaultInterval={1500}
                            />
                          </div>
                        </div>
                        <Button
                          onClick={handleCloseStory1}
                          className="elevatedElement iconBtn"
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            zIndex: 1000,
                            cursor: "pointer",
                          }}
                        >
                          <Cross />
                        </Button>
                      </>
                    </Modal>
                  )}
                </div>
                {isOverflowing && (
                  <button
                    className={Styles.rightButtonStory}
                    onClick={scrollRightStory}
                  >
                    <ArrowBackIosNew
                      fontSize="small"
                      style={{ rotate: "180deg" }}
                    />
                  </button>
                )}
              </div>

              <div>
                {postData.length > 0 && (
                  <div className={Styles.serviceListing}>
                    {postData.length > 3 && (
                      <div className={Styles.cardSliderHeader}>
                        <h2> Posts</h2>
                      </div>
                    )}
                    {postData.slice(0, 3).map((post: any) =>
                      // <PostCard
                      //   getAllPosts={getAllPosts}
                      //   post={post}
                      //   key={post._id}
                      // />
                      renderPostCard(post)
                    )}
                  </div>
                )}
              </div>

              <div style={{ marginLeft: "10px" }}>
                <BackgroundWhiteBox
                  title="Services"
                  seeAllUpper={filteredServices && filteredServices?.length > 0}
                  link={"/service"}
                  navigationButtons={
                    <div className={Styles.navButtons}>
                      <button
                        className={Styles.leftButton}
                        onClick={scrollLeftServices}
                      >
                        <ArrowBackIosNew fontSize="small" />
                      </button>
                      <button
                        className={Styles.rightButton}
                        onClick={scrollRightServices}
                      >
                        <ArrowBackIosNew fontSize="small" />
                      </button>
                    </div>
                  }
                >
                  <div className={Styles.cardSliderHeader}></div>
                  <div className={Styles.topRow} ref={scrollRefServices}>
                    {filteredServices?.length > 0 &&
                      filteredServices
                        .slice(0, 5)
                        .map((service: any) => (
                          <ServiceCardHome
                            service={service}
                            key={service.id}
                            setUpdateList={setServices}
                            handleRemoveService={handleRemoveService}
                            categoriesData={categoriesData}
                            refreshAPI={getAllServices}
                          />
                        ))}
                  </div>
                </BackgroundWhiteBox>
                <div>
                  {postData.length > 0 && (
                    <div className={Styles.serviceListing}>
                      {postData.length > 3 && (
                        <div className={Styles.cardSliderHeader}>
                          <h2> Posts</h2>
                        </div>
                      )}
                      {postData
                        .slice(3)
                        .map((post: any) => renderPostCard(post))}
                    </div>
                  )}
                </div>

                <BackgroundWhiteBox
                  title="Events"
                  seeAllUpper={events && events.length > 0}
                  link={"/events"}
                  navigationButtons={
                    <div className={Styles.navButtons}>
                      <button
                        className={Styles.leftButton}
                        onClick={scrollLeftEvents}
                      >
                        <ArrowBackIosNew fontSize="small" />
                      </button>
                      <button
                        className={Styles.rightButton}
                        onClick={scrollRightEvents}
                      >
                        <ArrowBackIosNew fontSize="small" />
                      </button>
                    </div>
                  }
                >
                  <div className={Styles.topRow} ref={scrollRefEvents}>
                    {events?.length > 0 &&
                      events
                        .slice(0, 5)
                        .map((event: any) => (
                          <EventCardHome
                            event={event}
                            key={event.id}
                            refreshAPI={getAllServicesEvents}
                          />
                        ))}
                  </div>
                </BackgroundWhiteBox>
              </div>
            </div>
          </Col>
          <Col span={6} className={Styles.mobileHide}>
            <div className={Styles.dashboardSection1}>
              <BackgroundWhiteBox
                title="Octopus"
                seeAllUpper={true}
                navigationButtons={
                  <div className={Styles.navButtons}>
                    <button className={Styles.leftButton} onClick={scrollLeft}>
                      <ArrowBackIosNew fontSize="small" />
                    </button>
                    <button
                      className={Styles.rightButton}
                      onClick={scrollRight}
                    >
                      <ArrowBackIosNew fontSize="small" />
                    </button>
                  </div>
                }
              >
                <div className={Styles.topRow} ref={scrollRef}>
                  {providers.length > 0 ? (
                    filteredProviders.map((topItem: any) => (
                      <TopCard
                        topItem={topItem}
                        key={topItem.id}
                        enableClick={true}
                        userType={"freelancer"}
                      />
                    ))
                  ) : (
                    <Typography
                      variant="subtitle2"
                      color={"grey"}
                      sx={{ alignItems: "center", width: "100%" }}
                    >
                      No data to available
                    </Typography>
                  )}
                </div>
              </BackgroundWhiteBox>
              <BackgroundWhiteBox
                title="Suppliers"
                seeAllUpper={true}
                navigationButtons={
                  <div className={Styles.navButtons}>
                    <button
                      className={Styles.leftButton}
                      onClick={scrollLeftSupplier}
                    >
                      <ArrowBackIosNew fontSize="small" />
                    </button>
                    <button
                      className={Styles.rightButton}
                      onClick={scrollRightSupplier}
                    >
                      <ArrowBackIosNew fontSize="small" />
                    </button>
                  </div>
                }
              >
                <div className={Styles.topRow} ref={scrollRefSupplier}>
                  {filteredSuppliers?.length > 0 ? (
                    filteredSuppliers.map((topItem: any) => (
                      <TopCard
                        topItem={topItem}
                        key={topItem.id}
                        enableClick={true}
                        userType={"supplier"}
                      />
                    ))
                  ) : (
                    <GenericPageForCards />
                  )}
                </div>
              </BackgroundWhiteBox>
              <BackgroundWhiteBox title="Top Brands" seeAllUpper={true}>
                <div className={Styles.topRow}>
                  <GenericPageForCards />
                </div>
              </BackgroundWhiteBox>
            </div>
            <StoryModal
              getAllStories={getAllStories}
              getAllPosts={getAllPosts}
              closeModal={closeStoryModal}
              openModal={storyModalOpen}
              handleSubmit={handleStoryOk}
            />
          </Col>
        </Row>
      </div>
      <LoginPopUp open={isLoginPopupOpen} onClose={handleCloseLoginPopup} />
      <CommentsDrawer
        postId={selectedPost?.post?._id}
        onClose={onClose}
        getAllPosts={getAllPosts}
        open={open}
        commentsData={selectedPost?.userCommentsWithDetails}
      />
      <PostDrawer
        visible={drawerVisible}
        onClose={closePostDrawer}
        post={selectedPost}
        onLike={LikePost}
        onUnlike={UnlikePost}
        onSave={handleSavedItems}
        // onCommentSubmit={() => onOpen()}
        isLiked={selectedPost?.post?.isLikedByUser}
        isSaved={selectedPost?.postUserDetails?.issaved}
        commentsData={selectedPost?.userCommentsWithDetails}
        getAllPosts={getAllPosts}
        selectedPost={setSelectedPost}
      />
    </>
  );
};

export default DashboardPage;
