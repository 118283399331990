import react, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Select,
  message,
  notification,
} from "antd";
import countries from "../Data/countries.json";
import Styles from "./PaymentInfo.module.css";
import { _POST, getZohoUserId } from "../../../common/commonFunc";
import { BASE_URL } from "../../../constant/ApplicationRoutes";
import FileUpload from "../../../components/InputComponents/FileUpload";
import axios from "axios";
import dayjs from "dayjs";
import { EmailOutlined } from "@mui/icons-material";
import { useHistory } from "react-router";
// @ts-ignore
import * as iban from "iban";
import { noSpecialCharactersValidator } from "../../../utils/helpers";

const PaymentInfo = ({ step }: any) => {
  const [form] = Form.useForm();
  const [checkboxState, setCheckboxState] = useState(false);
  const [beneficiaryType, setBeneficiaryType] = useState("Local");
  const [phoneNumbers, setPhoneNumbers] = useState([
    { number: "", countryCode: "+973", isPrimary: true, type: "business" },
  ]);
  const [representativeNumber, setRepresentativeNumber] = useState({
    number: "",
    countryCode: "+973",
    isPrimary: false,
    type: "representative",
  });
  const storedUserInfo: any = JSON.parse(
    localStorage.getItem("userInfo") || "{}"
  );

  const [existingEventImages, setExistingEventImages] = useState([]);
  const [newEventImages, setNewEventImages] = useState<any>([]);
  const history = useHistory();
  const [reset, SetReset] = useState(false);

  const zoho_user_id: any = getZohoUserId();
  const mail = localStorage.getItem("supplier_id");

  const email = mail;

  const { Option } = Select;

  const validateIBAN = (rule: any, value: any) => {
    if (value && !iban.isValid(value)) {
      return Promise.reject(new Error("Invalid IBAN"));
    }
    return Promise.resolve();
  };

  const updateEventImages = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setNewEventImages((prevImages: any[]) => [...prevImages, value]);
    else {
      const updatedImages = newEventImages;
      updatedImages.splice(index, 1);
      setNewEventImages(updatedImages);
    }
  };

  const getUpdatedImages = async () => {
    let allUpdatedImages: any = [...existingEventImages];

    if (newEventImages.length > 0) {
      const formData = new FormData();

      newEventImages.forEach((image: any) => {
        formData.append("images", image);
      });

      const response = await _POST(
        "uploadImagesToS3/supplier_documentRequest",
        formData,
        true
      );

      if (response && response.data?.length > 0) {
        allUpdatedImages = [...allUpdatedImages, ...response.data];
      } else {
        notification.open({
          type: "error",
          message: "Images Upload Failed",
          description: "Please try again to upload your images",
        });
      }
    } else {
      console.log("no new images");
    }

    return allUpdatedImages;
  };

  const SinglePhoneNumberInput = ({ value = {}, onChange }: any) => {
    const [number, setNumber] = useState(value.number || "");
    const [countryCode, setCountryCode] = useState(value.countryCode || "+973");

    const triggerChange = (changedValue: any) => {
      onChange?.({
        number,
        countryCode,
        ...value,
        ...changedValue,
      });
    };

    const onNumberChange = (e: any) => {
      const newNumber = e.target.value.replace(/\D/g, "");
      setNumber(newNumber);
      triggerChange({ number: newNumber });
    };

    const onCountryCodeChange = (newCountryCode: any) => {
      setCountryCode(newCountryCode);
      triggerChange({ countryCode: newCountryCode });
    };

    return (
      <div
        className={`${Styles.NormalInput} ${
          checkboxState ? Styles.disabled : ""
        }`}
      >
        <Input.Group compact className={`${Styles.phoneNumberInput}`}>
          <Select
            // value={countryCode}
            value={checkboxState ? storedUserInfo?.country_code : countryCode}
            onChange={onCountryCodeChange}
            className={Styles.selectCountryCode}
            showSearch
            optionFilterProp="children"
            disabled={checkboxState}
          >
            {countries.map((country) => (
              <Option key={country.code} value={country.dial_code}>
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                {country.dial_code}
              </Option>
            ))}
          </Select>
          <div className={Styles.dividerInput} />
          <Input
            style={{ width: "calc(100% - 100px)" }}
            // value={number}
            value={checkboxState ? storedUserInfo?.phone_no : number}
            onChange={onNumberChange}
            placeholder="Enter number"
            className={Styles.FormInput}
            disabled={checkboxState}
          />
        </Input.Group>
      </div>
    );
  };

  const convertAllValuesToStrings = (obj: any) => {
    // Create a deep copy of the object to avoid mutating the original data
    const result = JSON.parse(JSON.stringify(obj));

    // Function to check if an object should be fully converted to a string
    const shouldStringifyWholeObject = (value: any) => {
      return (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value) &&
        "countryCode" in value
      );
    };

    // Recursively convert all values to strings
    Object.keys(result).forEach((key) => {
      if (shouldStringifyWholeObject(result[key])) {
        // Convert the whole object to a string if it matches the condition
        result[key] = JSON.stringify(result[key]);
      } else if (
        typeof result[key] === "object" &&
        !Array.isArray(result[key]) &&
        result[key] !== null
      ) {
        result[key] = convertAllValuesToStrings(result[key]); // Recursive call for nested objects
      } else {
        result[key] = String(result[key]); // Convert all other types to string
      }
    });

    return result; // Return the modified object
  };

  const handleSubmit = async () => {
    try {
      const allValues = form.getFieldsValue();
      console.log("🚀 ~ handleSubmit ~ allValues:", allValues);
      const payload = convertAllValuesToStrings(allValues);
      console.log("🚀 ~ handleSubmit ~ payload:", payload);

      const uploadedImages: any = await getUpdatedImages();
      if (!uploadedImages || uploadedImages.length === 0) {
        return notification.open({
          type: "info",
          message: "Please upload at least one image",
          description: "Upload your IBAN certificate",
        });
      }
      payload.iban_certificate = JSON.stringify(uploadedImages);

      const response = await axios.post(
        `${BASE_URL}updatePaymentInfo/${email}`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        step(3);
        localStorage.setItem(
          "supplier_form_step",
          JSON.stringify(response?.data?.data?.supplier_form_step)
        );
        localStorage.setItem(
          "business_information",
          JSON.stringify(response?.data?.data?.business_info)
        );
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={{
        beneficiaryType: "Local",
        correspondentBankCountry: countries[0].name,
        beneficiaryCountry: countries[0].name,
      }}
      className={Styles.form}
    >
      <h1>Payment Information</h1>

      <Col>
        <Form.Item
          label="Beneficiary Type"
          name="beneficiaryType"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please select beneficiary type!",
            },
          ]}
        >
          <div className={Styles.selectInput}>
            <Select
              className={Styles.select}
              defaultActiveFirstOption
              placeholder="Local Beneficiary"
              onChange={(value) => {
                form.setFieldValue("beneficiaryType", value);
                setBeneficiaryType(value);
              }}
              defaultValue={"Local"}
            >
              <Select.Option
                key={"Local"}
                value={"Local"}
                optionFilterProp="children"
                default
                selected
                className={Styles.option}
              >
                &nbsp;&nbsp; {"Local Beneficiary"}
              </Select.Option>
              {/* <Select.Option
                key={"International"}
                value={"International"}
                optionFilterProp="children"
                className={Styles.option}
              >
                &nbsp;&nbsp;
                {"International Beneficiary"}
              </Select.Option> */}
            </Select>
          </div>
        </Form.Item>
      </Col>

      <Divider className={Styles.divider} />
      <div className={Styles.innerDiv}>
        <div className={Styles.innerDivlayout}>
          <Col>
            <Form.Item
              label="Beneficiary Full Name"
              name="beneficiaryFullName"
              rules={[
                {
                  required: true,
                  message: "Please input your full name!",
                },
                {
                  validator: noSpecialCharactersValidator(
                    "No special characters are allowed!"
                  ),
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <Input
                  placeholder="Enter your name"
                  className={Styles.FormInput}
                  onChange={() => console.log(form.getFieldsValue())}
                />
              </div>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Beneficiary Email"
              name="beneficiaryEmail"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <EmailOutlined className={Styles.mailIcon} />
                <Input
                  placeholder="Enter Email"
                  className={Styles.FormInput}
                  type="email"
                />
              </div>
            </Form.Item>
          </Col>
        </div>
        <div className={Styles.innerDivlayout}>
          <Col>
            <Form.Item
              label="Beneficiary IBAN"
              name="beneficiaryIBAN"
              rules={[
                {
                  required: true,
                  message: "Please input IBAN!",
                },
                { validator: validateIBAN },
              ]}
            >
              <div className={Styles.NormalInput}>
                <Input placeholder="Enter IBAN" className={Styles.FormInput} />
              </div>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="beneficiaryPhoneNumber"
              label="Beneficiary Phone Number"
              rules={[{ required: true }]}
            >
              <SinglePhoneNumberInput />
            </Form.Item>
          </Col>
        </div>
      </div>
      <Col>
        <p className={Styles.uploadText}>
          <span className={Styles.asterisk}>*</span> Upload IBAN Certificate
        </p>
        <FileUpload
          title1="Upload Image"
          description="PDF, JPG, PNG file with max size of 10mb."
          setData={updateEventImages}
          name="eventImages"
          existingPreviews={existingEventImages}
          setExistingPreviews={setExistingEventImages}
          reset={reset}
          alllowAll
          checkSensitiveContent={false}
        />
      </Col>
      <Divider className={Styles.divider} />

      {beneficiaryType === "International" && (
        <>
          <div className={Styles.innerDiv}>
            <div style={{ width: "100%" }}>
              <Col>
                <Form.Item
                  name="beneficiaryCountry"
                  label="Beneficiary Country"
                  rules={[{ required: true }]}
                >
                  <div className={Styles.selectInput}>
                    <Select
                      placeholder="Select your location"
                      className={Styles.select}
                      defaultValue={countries[0].name}
                      showSearch
                      optionFilterProp="value"
                      filterOption={(input, option: any) =>
                        option!.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) =>
                        form.setFieldsValue({ beneficiaryCountry: value })
                      }
                    >
                      {countries
                        .filter((country) => country.name === "Bahrain")
                        .map((country) => (
                          <Option
                            key={country.code}
                            value={country.name}
                            className={Styles.option}
                          >
                            <img
                              src={country.flag}
                              alt={country.name}
                              style={{ width: "20px", marginRight: "10px" }}
                              className={Styles.flagIcon}
                            />
                            {country.name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <p></p>
                </Form.Item>
              </Col>
            </div>
          </div>

          <div className={Styles.innerDiv}>
            <div className={Styles.innerDivlayout}>
              <Col>
                <Form.Item
                  label="Beneficiary Bank Name"
                  name="beneficiaryBankName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your bank name!",
                    },
                  ]}
                >
                  <div className={Styles.NormalInput}>
                    <Input
                      placeholder="Enter your bank name"
                      className={Styles.FormInput}
                      onChange={() => console.log(form.getFieldsValue())}
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  label="Correspondent Bank"
                  name="correspondentBank"
                  rules={[
                    {
                      required: true,
                      message: "Please input your correspondent bank!",
                    },
                  ]}
                >
                  <div className={Styles.NormalInput}>
                    <Input
                      placeholder="Enter correspondent bank"
                      className={Styles.FormInput}
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  label="Routing Number"
                  name="routingNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your routing number!",
                    },
                  ]}
                >
                  <div className={Styles.NormalInput}>
                    <Input
                      placeholder="Enter routing number"
                      className={Styles.FormInput}
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  label="Correspondent Swift/BIC Code"
                  name="correspondentSwiftBicCode"
                  rules={[
                    {
                      required: true,
                      message: "Please input Swift Bic Code!",
                    },
                  ]}
                >
                  <div className={Styles.NormalInput}>
                    <Input
                      placeholder="Enter Swift Bic Code"
                      className={Styles.FormInput}
                    />
                  </div>
                </Form.Item>
              </Col>
            </div>
            <div className={Styles.innerDivlayout}>
              <Col>
                <Form.Item
                  label="SWIFT/BIC Code"
                  name="beneficiarySwiftBicCode"
                  rules={[
                    {
                      required: true,
                      message: "Please input Swift Bic Code !",
                    },
                  ]}
                >
                  <div className={Styles.NormalInput}>
                    <Input
                      placeholder="Enter Swift Bic Code"
                      className={Styles.FormInput}
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  name="correspondentBankCountry"
                  label="Correspondent Bank Country"
                  rules={[{ required: true }]}
                >
                  <div className={Styles.selectInput}>
                    <Select
                      placeholder="Select your bank location"
                      className={Styles.select}
                      defaultValue={countries[0].name}
                      showSearch
                      optionFilterProp="value"
                      filterOption={(input, option: any) =>
                        option!.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) =>
                        form.setFieldsValue({ correspondentBankCountry: value })
                      }
                    >
                      {countries
                        .filter((country) => country.name === "Bahrain")
                        .map((country) => (
                          <Option
                            key={country.code}
                            value={country.name}
                            className={Styles.option}
                          >
                            <img
                              src={country.flag}
                              alt={country.name}
                              style={{ width: "20px", marginRight: "10px" }}
                              className={Styles.flagIcon}
                            />
                            {country.name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <p></p>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  label="Beneficiary Account Number"
                  name="beneficiaryAccountNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your account number!",
                    },
                  ]}
                >
                  <div className={Styles.NormalInput}>
                    <Input
                      placeholder="Enter account number"
                      className={Styles.FormInput}
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  label="Correspondent IFSC"
                  name="correspondentIFSC"
                  rules={[
                    {
                      required: true,
                      message: "Please input IFSC!",
                    },
                  ]}
                >
                  <div className={Styles.NormalInput}>
                    <Input
                      placeholder="Enter IFSC"
                      className={Styles.FormInput}
                    />
                  </div>
                </Form.Item>
              </Col>
            </div>
          </div>

          <Divider />
        </>
      )}
      <div className={Styles.innerDiv}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flex: 1,
            gap: "1rem",
          }}
        >
          {/* <Button
            className={Styles.continue}
            style={{
              maxWidth: "20%",
              backgroundColor: "#30263E",
            }}
            onClick={() => {
              step(1);
            }}
          >
            Previous
          </Button> */}
          <Button
            className={Styles.continue}
            style={{
              maxWidth: "20%",
            }}
            type="primary"
            htmlType="submit"
          >
            Continue
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default PaymentInfo;
