import { Button, Drawer, Popconfirm, Space, notification } from "antd";
import React, { useState } from "react";
import Styles from "./AffiliateProducts.module.css";
import Cross from "../../../assests/customIcons/Cross";
import { ListItem } from "quill-delta-to-html";
import { _PATCH, _POST } from "../../../common/commonFunc";
import { CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { showConfirmCustomModal } from "../../../common/CustomModal/CustomModal";
import CollabDetails from "../../SupplierScreens/Inventory/CollabDetails";
import RejectContractModal from "./RejectContractModal";


export const ContractDetailsDrawer = ({
  onOpen,
  onClose,
  contractDetails,
  fetchData,
}: any) => {
  const [openRejectModal,setOpenRejectModal] = useState(false)
  const [rejectContactId,setRejectContactId] = useState("")
  const [rejectContactMode,setRejectContactMode] = useState("")

  console.log("Non Product Drawer Data", contractDetails);


  function getPlainText(text : any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return doc.body.textContent || "";
  }

  const handleSignContract_np = async (contractId: string) => {

    const payload = {}
    try {
      const response = await _PATCH(
        `supplier/freelancer/signNonProductContract/${contractId}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Contract Signed!",
            description: "Your contract has been signed successfully",
          });
          fetchData()
          onClose();
        } else {
          notification.open({
            type: "error",
            message: "Contract Not signed !!",
            description: "Your contract was not signed ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleMarkComplete = async (details : any) => {
      try {
        const completed = await _POST(`supplier/freelacner/completeContract?contractId=${details.contract_id}&contract_mode=${details.contract_mode}`, {}, true);
        if (completed.status) {
          notification.open({
            type: "success",
            message: "Contact Marked Completed",
            description: "This contract has been completed successfully.",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Unable to Marke the contract complete",
            description: "This contract was not marked complete.",
          });
        }
      } catch (error) {
        console.error(error);
      }
  

  }

  const handleRejectContract_np = async (contractId: string) => {

  }


  const handleAcceptRequest = async () => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/accept-collaboration-request/${contractDetails?.request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Acccepted !!",
            description: "Your Collaboration has been established Successfully",
          });
          fetchData()
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Accepted !!",
            description: "Your Collaboration was not accepted ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const extendCollaborationRequest = async () => {
    const payload = {};
    try {
      const response = await _POST(
        `supplier/extend-collaboration-request/${contractDetails?.request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Extended  !!",
            description: "Your Collaboration has been extended Successfully",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Extended !!",
            description: "Your Collaboration was not extended ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const endCollaborationRequest = async () => {
    const payload = {};
    try {
      const response = await _POST(
        `supplier/end-collaboration-request/${contractDetails?.request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Terminated  !!",
            description: "Your Collaboration has been ended Successfully",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Terminated !!",
            description: "Your Collaboration was not terminated ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rejectCollaborationRequest = async () => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/reject-collaboration-request/${contractDetails?.request_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Rejected !!",
            description: "Your Collaboration has been rejected Successfully",
          });
          fetchData();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Rejected !!",
            description: "Your Collaboration was not rejected ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmExtendRequest = () => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure want to extend this Collaboration Request ?",
      smallText:
        "Extending this request leads to extend your collaboration period for this supplier.",
      onOk: () => extendCollaborationRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const confirmAcceptRequest = () => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure want to accept this Collaboration Request ?",
      smallText:
        "Accepting this request will establish a collaboration with this supplier.",
      onOk: () => handleAcceptRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const confirmRejectRequest = () => {
    //  event.preventDefault();
    showConfirmCustomModal({
      title: "Are you sure want to reject this Collaboration Request ?",
      smallText:
        "Rejecting this request leads to permanently remove the request for this supplier.",
      onOk: () => rejectCollaborationRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const confirmEndCollaboration = () => {
    showConfirmCustomModal({
      title: "Are you sure want to end Collaboration with this supplier ?",
      smallText:
        "Ending this Collboration leads to terminate all the collaboration data that was being shared.",
      onOk: () => endCollaborationRequest(),
      okText: "Yes",
      cancelText: "No",
    });
  };
  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const handleRejectContract = (contract_id : string, contract_mode : string) => {
    setOpenRejectModal(true)
    setRejectContactId(contract_id)
    setRejectContactMode(contract_mode)
  }

  const closeRejectContractModal = () => {
    setOpenRejectModal(false)
    setRejectContactId("")
    setRejectContactMode("")
  }

  return (
    <>
      <Drawer
        title=" "
        placement="right"
        onClose={onClose}
        open={onOpen}
        key="right"
        width={"600px"}
        closable={false}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <div style={{ display: "flex" }}>
          <img
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              marginLeft: "10px",
            }}
            src={contractDetails?.supplier_profile_pic === "NULL" ? "https://picsum.photos/200?random=1" :contractDetails?.supplier_profile_pic }
            alt={""}
          />
          <h3>
            <b>
              &nbsp; &nbsp; &nbsp; By {contractDetails?.supplier_first_name}{" "}
              {contractDetails?.supplier_last_name}
            </b>
          </h3>
        </div>
        <br />
        <br />
        <div>
          {contractDetails?.contract_status?.toLowerCase() === "accepted" ? (
            <Button
              style={{ cursor: "not-allowed" }}
              className={Styles.assigned}
            >
              Accepted Contract
            </Button>
          ) : contractDetails?.contract_status === "pending" ? (
            <Button className={Styles.pending}>Pending Contract</Button>
          ) : contractDetails?.contract_status === "Rejected" ? (
            <Button className={Styles.rejected}>Rejected Contract</Button>
          ) : (
            <></>
          )}
        </div>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              component="div"
              variant="h5"
              fontWeight={700}
              gutterBottom
            >
              Contract Details
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Contract ID:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {contractDetails?.contract_id}
              </div>
            </Typography>
            <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Supplier Name:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {contractDetails?.supplier_first_name}{" "}
                {contractDetails?.supplier_last_name}
              </div>
            </Typography>
           {contractDetails?.collaboration_start_date && contractDetails?.collaboration_end_date && <Typography
              variant="subtitle2"
              color={"text.secondary"}
              sx={{ display: "flex", flexDirection: "row" }}
              gutterBottom
            >
              Collaboration Duration:{" "}
              <div style={{ color: "black", marginLeft: "3px" }}>
                {formatDate(contractDetails?.collaboration_start_date)}
                {" to"} {formatDate(contractDetails?.collaboration_end_date)}
              </div>
            </Typography>}
            <Box component="div" marginTop={3}>
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Contracts
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "230px",
                overflowY: "scroll",
              }}
            >
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                {contractDetails ? (
                  <>
                    
                      <div className={Styles.contractDiv}>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent:"space-between" }}>
                          <Typography variant="body2" fontWeight={"bold"} color={"black"}> Fixed Amount </Typography>
                          <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {contractDetails?.fixed_amount}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent:"space-between" }}>
                          <Typography variant="body2" fontWeight={"bold"} color={"black"}> Vat Amount </Typography>
                          <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {contractDetails?.vat_amount_to_pay}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent:"space-between" }}>
                          <Typography variant="body2" fontWeight={"bold"} color={"black"}> Total Amount </Typography>
                          <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {contractDetails?.total_amount_including_vat}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent:"space-between" }}>
                          <Typography variant="body2" fontWeight={"bold"} color={"black"}> Platform Fee </Typography>
                          <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>- BHD {contractDetails?.platform_fee}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent:"space-between" }}>
                          <Typography variant="body2" fontWeight={"bold"} color={"black"}> Total Amount To be Recieved from Supplier </Typography>
                          <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}>BHD {contractDetails?.service_provider_final_amount}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent:"space-between" }}>
                          <Typography variant="body2" fontWeight={"bold"} color={"black"}> Advance Amount Percentage </Typography>
                          <Typography variant="body2" fontWeight={"bold"} color={"grey"} marginLeft={1}> {contractDetails?.advance_amount_percentage} %</Typography>
                        </div>
                    
                        {contractDetails?.is_advance_amount_paid ?
                          (
                            <>
                              <Typography variant="body2" color={"green"}>Advance amount <b>BHD {contractDetails?.advance_payment}</b> is paid</Typography>
                              <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                              <Popconfirm
              title="Are you sure you want to mark this contract complete?"
              onConfirm={() =>{handleMarkComplete(contractDetails)}}
              okText="Yes"
              cancelText="No"
            >
             {!contractDetails.is_balance_amount_paid && <Button className={Styles.buttonFilled_}>
                                  Mark Contract Complete
                                </Button>}
              </Popconfirm>
                                
                              </div>
                            </>
                          ) :
                          <>

                            <Typography variant="subtitle2" color={"red"}>Advance payment of <b>BHD {contractDetails?.advance_payment}</b>  is pending</Typography>
                            {!contractDetails?.is_signed &&
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Popconfirm
                                  title="Are you sure you want to accept this contract?"
                                  onConfirm={() => handleSignContract_np(contractDetails?.contract_id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button className={Styles.buttonFilled}>Accept Contract</Button>
                                </Popconfirm>

                               
                                  <Button className={Styles.buttonDark} onClick={() => {handleRejectContract(contractDetails.contract_id, contractDetails.contract_mode)}}>Reject Contract</Button>
                              </div>
                            }
                          </>
                        }
                      </div>
                   
                  </>
                ) : (
                  "No Contracts Available"
                )}
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
              marginTop={3}
            >
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Detailed Information on Promotional Products
              </Typography>
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                <li> {getPlainText(contractDetails?.collaboration_detailed_info ?? " ")}</li>
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
              marginTop={3}
            >
              <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                Collaboration Interest & Expectations
              </Typography>
              <Typography
                variant="caption"
                color={"text.secondary"}
                gutterBottom
              >
                <li>{getPlainText(contractDetails?.collaboration_interest_expectation ?? " ")}</li>
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "row" }}
              marginTop={3}
              gap={2}
            >
              {contractDetails?.collaboration_status === "Accepted" ? (
                <Box
                  sx={{ display: "flex", flexDirection: "row" }}
                  marginTop={3}
                  gap={2}
                >
                  <Button
                    className={Styles.cancel}
                    onClick={() => confirmEndCollaboration()}
                  >
                    {" "}
                    End Collaboration
                  </Button>
                  <Button
                    className={Styles.accept}
                    onClick={() => confirmExtendRequest()}
                  >
                    Extend Collaboration
                  </Button>
                </Box>
              ) : contractDetails?.collaboration_status ===
                "pending" ? (
                <Box
                  sx={{ display: "flex", flexDirection: "row" }}
                  marginTop={3}
                  gap={2}
                >
                  <Button
                    className={Styles.cancel}
                    onClick={() => confirmAcceptRequest()}
                  >
                    Accept Collaboration
                  </Button>
                  <Button
                    className={Styles.accept}
                    onClick={() => confirmRejectRequest()}
                  >
                    Reject Collaboration
                  </Button>
                </Box>
              ) : (
                contractDetails?.collaboration_status ===
                "Rejected" && (
                  <Box
                    sx={{ display: "flex", flexDirection: "row" }}
                    marginTop={3}
                    gap={2}
                  >
                    <Button className={Styles.rejected}>Rejected</Button>
                  </Box>
                )
              )}
            </Box>
          </CardContent>
        </Box>
      </Drawer>

      <RejectContractModal
        isVisible={openRejectModal}
        onClose={closeRejectContractModal}
        contract_id={rejectContactId}
        contract_mode={rejectContactMode}
        fetchData = {fetchData}
      />
    </>
  );
};
