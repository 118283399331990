import { Box, CardContent, Typography } from "@mui/material";
import {
  Button,
  Drawer,
  Popconfirm,
  Space,
  notification,
} from "antd";
import User from "../../../assests/RandomUser.jpeg";
import Styles from "./AffiliateProducts.module.css";
import Cross from "../../../assests/customIcons/Cross";
import { _PATCH } from "../../../common/commonFunc";

export const CollabDetailsDrawer = ({
  collabDetails,
  onClose,
  open,
  fetchData,
  type,
}: any) => {


  function getPlainText(text: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return doc.body.textContent || "";
  }


  const handleAcceptProduct = async (contract_id: string) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/upload-sign-contract/${contract_id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Product Collaboration Signed !!",
            description: "Your Collaboration has been signed Successfully",
          });
          fetchData();
          onClose();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Created !!",
            description: "Your Collaboration was not created ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectProduct = async (contract_id: string) => {
  }

  const handleAcceptRequest = async (id: string) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/accept-collaboration-request/${id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Acccepted !!",
            description: "Your Collaboration has been established Successfully",
          });
          fetchData();
          onClose();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Accepted !!",
            description: "Your Collaboration was not accepted ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectRequest = async (id: string) => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/reject-collaboration-request/${id}`,
        payload,
        true
      );
      if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Collaboration Rejected !!",
            description: "Your Collaboration has been rejected Successfully",
          });
          fetchData();
          onClose();
        } else {
          notification.open({
            type: "error",
            message: "Collaboration Not Rejected !!",
            description: "Your Collaboration was not rejected ",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };


  return (
    <Drawer
      title="Collaboration Details"
      placement="right"
      onClose={onClose}
      open={open}
      key="right"
      width={"550px"}
      closable={false}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginLeft: "10px",
          }}
          src={collabDetails?.supplier_profile_pic === "NULL" || collabDetails?.supplier_profile_pic === null ? "https://picsum.photos/200?random=1" : collabDetails?.supplier_profile_pic}
          alt={User}
        />
        <h3>
          <b>
            &nbsp; &nbsp; &nbsp; By {collabDetails?.supplier_first_name}{" "}
            {collabDetails?.supplier_last_name}
          </b>
        </h3>
      </div>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            variant="subtitle2"
            color={"text.secondary"}
            sx={{ display: "flex", flexDirection: "row" }}
            gutterBottom
          >
            Collaboration ID:{" "}
            <div style={{ color: "black", marginLeft: "3px" }}>
              {collabDetails?.request_id ?? collabDetails?.collaboration_request_id}
            </div>
          </Typography>

          <Typography
            variant="subtitle2"
            color={"text.secondary"}
            sx={{ display: "flex", flexDirection: "row" }}
            gutterBottom
          >
            Collaboration Duration:{" "}
            <div style={{ color: "black", marginLeft: "3px" }}>
              {formatDate(collabDetails?.collaboration_start_date)}
              {" to"} {formatDate(collabDetails?.collaboration_end_date)}
            </div>
          </Typography>
          {type === 'rejectedCollab' && (
            <>
              <Typography style={{ color: 'red', marginTop: '30px' }}>REJECTED</Typography>
            </>
          )}


          {type === 'prodDetails' &&
            <>
              <Box component="div" marginTop={3}>
                <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                  Product Details
                </Typography>
              </Box>

              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "450px",
                  overflowY: "scroll",
                  background: "#f2f0ff",
                  padding: "10px",
                  borderRadius: "10px"
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "5px" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "15px" }}>
                    {collabDetails?.product_images?.length > 4 ?
                      <img
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50%",
                          marginRight: "5px"
                        }}
                        src={collabDetails?.product_images[0]}
                        alt={"Product Image"}
                      />
                      :
                      collabDetails?.product_images?.map((p: any) => (
                        <img
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                            marginRight: "5px"
                          }}
                          src={p}
                          alt={"Product Image"}
                        />))
                    }

                  </div>
                  <Typography
                    variant="subtitle2"
                    sx={{ display: "flex", flexDirection: "row" }}
                    fontWeight={700}
                    gutterBottom
                  >
                    Product Name:{" "}
                    <div style={{ color: "grey", marginLeft: "5px" }}>
                      {collabDetails?.product_name}
                    </div>
                  </Typography>
                  {collabDetails?.commission_rate > 0 ? <Typography
                    variant="subtitle2"
                    sx={{ display: "flex", flexDirection: "row" }}
                    fontWeight={700}
                    gutterBottom
                  >
                    Commission rate:{" "}
                    <div style={{ color: "grey", marginLeft: "5px" }}>
                      {collabDetails?.commission_rate} %
                    </div>
                  </Typography>
                    :
                    <Typography
                      variant="subtitle2"
                      sx={{ display: "flex", flexDirection: "row" }}
                      fontWeight={700}
                      gutterBottom
                    >
                      Fixed Amount:{" "}
                      <div style={{ color: "grey", marginLeft: "5px" }}>
                        <b>BHD </b>{collabDetails?.fixed_amount}
                      </div>
                    </Typography>}
                  <Typography
                    variant="subtitle2"
                    sx={{ display: "flex", flexDirection: "row" }}
                    fontWeight={700}
                    gutterBottom
                  >
                    Assigned Quantity:{" "}
                    <div style={{ color: "grey", marginLeft: "5px" }}>
                      {collabDetails?.assigned_quantity}
                    </div>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ display: "flex", flexDirection: "row" }}
                    fontWeight={700}
                    gutterBottom
                  >
                    Sold Quantity:{" "}
                    <div style={{ color: "grey", marginLeft: "5px" }}>
                      {collabDetails?.sold_quantity}
                    </div>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ display: "flex", flexDirection: "row" }}
                    fontWeight={700}
                    gutterBottom
                  >
                    Available Quantity:{" "}
                    <div style={{ color: "grey", marginLeft: "5px" }}>
                      {collabDetails?.available_quantity}
                    </div>
                  </Typography>

                  <Typography variant="subtitle2" fontWeight={700} gutterBottom>
                    Product Description:{" "}
                  </Typography>
                  <li>
                    <Typography variant="caption" gutterBottom>{getPlainText(collabDetails?.product_description ?? " ")}</Typography>
                  </li>
                </Box>
              </Box>
            </>
          }
          {collabDetails?.collaboration_interest_expectation && <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column" }}
            marginTop={3}
          >
            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
              Collaboration Interest Expectations
            </Typography>
            <li>
              <Typography variant="caption" color={"text.secondary"} gutterBottom>{getPlainText(collabDetails?.collaboration_interest_expectation ?? " ")}</Typography>
            </li>
          </Box>}
          {collabDetails?.collaboration_detailed_info && <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column" }}
            marginTop={3}
          >
            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
              Detailed Information on Promotional Products
            </Typography>
            <li>
              <Typography variant="caption" color={"text.secondary"} gutterBottom>{getPlainText(collabDetails?.collaboration_detailed_info ?? " ")}</Typography>
            </li>
          </Box>}
          {type === 'collab' && <Box
            component="div"
            sx={{ display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}
            marginTop={5}
          >
            <Popconfirm
              title="Are you sure you want to accept this collaboration?"
              onConfirm={() => handleAcceptRequest(collabDetails?.request_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className={Styles.acceptButton}
              >
                Accept Collaboration
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to reject this collaboration?"
              onConfirm={() => handleRejectRequest(collabDetails?.request_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className={Styles.cancel}>
                Reject Collaboration
              </Button>
            </Popconfirm>
          </Box>}

          {type === 'prodDetails' && !collabDetails.assigned_status && <Box
            component="div"
            sx={{ display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}
            marginTop={5}
          >
            <Popconfirm
              title="Are you sure you want to accept this product?"
              onConfirm={() => handleAcceptProduct(collabDetails?.contract_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className={Styles.acceptButton}
              >
                Accept Product
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to reject this product?"
              onConfirm={() => handleRejectProduct(collabDetails?.contract_id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className={Styles.cancel}>
                Reject Product
              </Button>
            </Popconfirm>
          </Box>}
        </CardContent>
      </Box>
    </Drawer>
  );
};
