import React, { useState } from "react";
import { Table, Button, Empty, Modal, notification } from "antd";
import {
  PlusOutlined,
  EyeOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import styles from "./ShareholdersInfo.module.css";
import PersonalInfoShareHolder from "./PersonalInfoCard";
import { _POST } from "../../../../../common/commonFunc";
import ShareholderForm from "../../../../SupplierRegister/components/ShareHolder/ShareHolderFormNext";

interface ShareholdersInfoProps {
  shareholders: any[];
  admins?: any[];
  onAddShareholderSuccess?: () => void;
  showAdd?: boolean;
}

const ShareholdersInfo: React.FC<ShareholdersInfoProps> = ({
  shareholders,
  admins,
  onAddShareholderSuccess,
  showAdd = false,
}) => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [selectedShareholder, setSelectedShareholder] = useState<any>(null);
  const [newShareholder, setNewShareholder] = useState<any>(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [originalShareholders] = useState<any[]>(shareholders || []);

  const convertDataToFormDataImages = (shareholders: any) => {
    const formData = new FormData();
    shareholders.forEach((shareholder: any, index: any) => {
      Object.entries(shareholder).forEach(([key, value]: any) => {
        const formKey = `shareholders[${index}].${key}`;
        if (key === "id") return;
        if (key === "contactPersonBirthdate" && value) {
          const date = new Date(value);
          const formattedDate = `${date
            .getDate()
            .toString()
            .padStart(2, "0")}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getFullYear()}`;
          formData.append(formKey, formattedDate);
        } else if (
          key === "national_id_front_side" ||
          key === "national_id_back_side"
        ) {
          if (value instanceof File) {
            formData.append(formKey, value, value.name);
          } else if (Array.isArray(value) && value[0] instanceof File) {
            value.forEach((file) => formData.append(formKey, file, file.name));
          }
        } else if (
          typeof value === "object" &&
          value !== null &&
          !(value instanceof File)
        ) {
          formData.append(formKey, JSON.stringify(value));
        } else if (value !== undefined) {
          formData.append(formKey, value.toString());
        }
      });
    });
    return formData;
  };

  const handleUploadAndStateUpdate = async (values: any) => {
    const formData = convertDataToFormDataImages(values);
    formData.append("total_shareholders", "1");

    try {
      const response = await _POST("create/shareholder", formData, true);
      if (response && response.status === true) {
        notification.open({
          type: "success",
          message: "Shareholder added successfully",
        });

        if (onAddShareholderSuccess) {
          onAddShareholderSuccess();
        }

        setConfirmModalVisible(false);
        setFormVisible(false);
        return true;
      } else {
        notification.open({
          type: "error",
          message: "Failed to add shareholder",
          description: `${response.message}`,
        });
        return false;
      }
    } catch (error) {
      console.error("Failed to upload data:", error);
      notification.open({
        type: "error",
        message: "Upload Failed",
        description: "An error occurred while trying to upload data.",
      });
      return false;
    }
  };

  const handleConfirmSave = async () => {
    const success = await handleUploadAndStateUpdate([newShareholder]);
    if (success) {
      setConfirmModalVisible(false);
    }
  };

  const handleOpenForm = () => {
    setNewShareholder(null);
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
  };

  const handleFormSubmit = (shareholder: any) => {
    setNewShareholder(shareholder);
    setConfirmModalVisible(true);
  };

  const showDetails = (shareholder: any) => {
    setSelectedShareholder(shareholder);
  };

  const hideDetails = () => {
    setSelectedShareholder(null);
  };

  const originalColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_no",
      key: "phone_no",
      render: (text: string, record: any) =>
        `${record.country_code} ${text || "Not Available"}`,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <>
          <Button
            className={styles.saveBtn}
            icon={<EyeOutlined />}
            onClick={() => showDetails(record)}
          >
            View
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {!selectedShareholder ? (
        <>
          {showAdd && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleOpenForm}
              className={styles.saveBtn}
            >
              Add Shareholder
            </Button>
          )}

          <h3>Existing Shareholders</h3>
          {originalShareholders && originalShareholders.length > 0 ? (
            <Table
              dataSource={originalShareholders}
              columns={originalColumns}
              rowKey="id"
              pagination={{ pageSize: 500 }}
            />
          ) : (
            <Table
              dataSource={[]}
              columns={originalColumns}
              rowKey="id"
              pagination={{ pageSize: 5 }}
              locale={{
                emptyText: (
                  <div>
                    <p>No Existing Shareholders Found</p>
                    <Empty description="No existing shareholders available." />
                  </div>
                ),
              }}
            />
          )}
        </>
      ) : (
        <>
          <Button
            type="primary"
            icon={<ArrowLeftOutlined />}
            onClick={hideDetails}
            className={styles.saveBtn}
          >
            Back
          </Button>
          <PersonalInfoShareHolder
            userInfo={selectedShareholder}
            onSave={hideDetails}
            onCancel={hideDetails}
            admins={admins}
          />
        </>
      )}

      {isFormVisible && (
        <ShareholderForm
          visible={isFormVisible}
          onClose={handleCloseForm}
          onSubmit={handleFormSubmit}
          shareholder={newShareholder}
        />
      )}

      <Modal
        open={confirmModalVisible}
        centered
        onOk={handleConfirmSave}
        onCancel={() => setConfirmModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            className={styles.exitBTNModalStay}
            onClick={handleConfirmSave}
          >
            Yes
          </Button>,
          <Button
            key="submit"
            type="primary"
            className={styles.exitBTNModalExit}
            onClick={() => setConfirmModalVisible(false)}
          >
            Cancel
          </Button>,
        ]}
      >
        <p className={styles.exitText}>
          Are you sure you want to add this shareholder?
        </p>
        <p className={styles.exitText}>Name: {newShareholder?.name}</p>
        <p className={styles.exitText}>Email: {newShareholder?.email}</p>
      </Modal>
    </>
  );
};

export default ShareholdersInfo;
