import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row, notification } from "antd";
import RatingStar from "../../../assests/customIcons/RatingStar";
import { getCapitalize } from "../../../utils/helpers";
import Edit from "../../../assests/customIcons/Edit";
import Delete from "../../../assests/customIcons/Delete";
import ImageGallery from "react-image-gallery";
import "./ServiceCard.css";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import LeftCircle from "../../../assests/customIcons/LeftCircle";
import RightCircle from "../../../assests/customIcons/RightCircle";
import { EventAvailable } from "@mui/icons-material";
import { getRole, _DELETE, _GET, _POST } from "../../commonFunc";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";
import Saved from "../../../assests/ItemSaved.png";
import Cross from "../../../assests/customIcons/Cross";
import Save from "../../../assests/customIcons/Save";
import Comment from "../../../assests/customIcons/Comment";
import Share from "../../../assests/customIcons/Share";
import StoryAvatar from "../../StoryAvatar/StoryAvatar";
import { getShortenText, getMonthDateYear } from "../../../utils/helpers";
import "../PostCard/PostCard.css";
import _ from "lodash";
import { RWebShare } from "react-web-share";
import { StarFilled } from "@ant-design/icons";
import LoginPopUp from "../../../pages/Login/LoginPopup";

const ServiceCardHome = React.memo(
  ({
    service,
    setUpdateList,
    similarBy,
    categoriesData,
    handleRemoveService,
    refreshAPI,
  }: any) => {
    const [freelancerProfileData, setFreelancerProfileData] = useState<any>([]);

    function stripHtml(html: string) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || "";
    }
    console.log("SERVICE DATA FOR DISPLAY IS", service);

    const plainTextDesc = stripHtml(service?.service_desc);
    const shareText = `Checkout New Service:\n Service Name: ${
      service?.service_name
    }\n ${plainTextDesc.slice(0, 100)}\n Link:\n`;

    const userRole: any = "home";
    const [isProvider, setIsProvider] = useState(
      userRole === "supplier" || userRole === "freelancer"
    );
    const [isProfilePicError, setIsProfilePicError] = useState(false);
    const token = localStorage.getItem("jwtToken");
    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const renderAvatar = () => {
      const { profile_pic, first_name } = service.service_provider_details;

      if (profile_pic && profile_pic.trim() !== "") {
        return (
          <img
            src={profile_pic}
            onError={() => setIsProfilePicError(true)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
            alt="Profile"
          />
        );
      } else {
        const initial = first_name ? first_name[0] : "";
        const bgColor = getRandomColor();
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              backgroundColor: bgColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "20px",
            }}
          >
            {initial.toUpperCase()}
          </div>
        );
      }
    };
    const handleDeleteService = async () => {
      if (isProvider) {
        const response = await _DELETE(
          "services/deleteServiceById/" + service.id,
          true
        );
        if (response) {
          if (response.message === "Service Deleted Successfully") {
            setUpdateList(true);
            showSuccessCustomModal({
              title: "Service Deleted successfully.",
              smallText: "Your service has been deleted successfully.",
              buttonText: "Continue",
            });
          } else {
            notification.open({
              type: "error",
              message: "Something Went Wrong",
              description: "Please try again to delete your service",
            });
          }
        }
      } else {
        return notification.open({
          type: "error",
          message: "Something Went Wrong",
          description: "Login as Service Provider to use this service",
        });
      }
    };
    const handleSavedItems = async () => {
      const payload = {
        itemType: "service",
        itemId: service.id,
      };

      try {
        const saveData = await _POST("userProfile/saveItem", payload, true);
        if (saveData) {
          notification.open({
            type: "success",
            message: "Service Saved Successfully",
            description: " This Service has been saved successfully.",
          });
          refreshAPI();
        } else {
          notification.open({
            type: "error",
            message: "Unabloe to save event.",
            description: " This Service can't be save right now !",
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const confirmDeleteService = (event: any) => {
      event.preventDefault();
      showConfirmCustomModal({
        title: "Are you sure you want to delete ?",
        smallText:
          "If you want to delete this service then you have to cancel all orders of this service",
        onOk: handleDeleteService,
        okText: "Yes",
        cancelText: "No",
      });
    };

    const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);
    const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);

    const closeProfileDrawer = () => {
      setOpenProfileDrawer(false);
    };

    const freelancerProfile = async () => {
      //  https://development.theoctopus.ai:8443/userProfile/freelancerProfile/52a608e7-e2c1-4d2d-9e28-e78ec80d7231
      try {
        const response = await _GET(
          `userProfile/freelancerProfile/${service.provider_id}`,
          false
        );
        setFreelancerProfileData(response);
      } catch (error) {
        console.error("Error fetching freelancer profile:", error);
        // Handle the error appropriately
      }
    };

    return (
      <Row className="postElementsRow">
        <Col span={21}>
          <Link
            to={{
              pathname: `${APPLICATION_ROUTES.SERVICE}/${service.id}`,
              state: {
                serviceData: service,
                categoriesInfo: categoriesData,
              },
            }}
            className="serviceCard"
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "400px",
              }}
            >
              {service.service_images && service.service_images.length > 0 && (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    backgroundColor: "#eee",
                    border: "1px solid #eee",
                  }}
                  //   picsum image if not there
                  src={
                    service.service_images[0]
                      ? service.service_images[0]
                      : "https://picsum.photos/200/300"
                  }
                />
              )}

              {service.service_images === null && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    backgroundColor: "#eee",
                  }}
                />
              )}
              {isProvider && (
                <div className="cardActions">
                  <button
                    onClick={confirmDeleteService}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Delete />
                  </button>
                  <button
                    onClick={() => {}}
                    className="elevatedElement iconBtn"
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  >
                    <Edit />
                  </button>
                </div>
              )}
              {service.service_name && (
                <>
                  <div
                    className="cardCategory"
                    style={{
                      border: "1px solid #eee",
                    }}
                  >
                    <p
                      style={{ fontSize: "16px", color: "#30263E", margin: 0 }}
                    >
                      {service.service_name}
                    </p>
                  </div>
                  <div className="serviceCapsule">Service</div>
                </>
              )}
            </div>
            {service.service_rating ? (
              <div className="cardRatingServiceCard">
                <div className="ratingContainer">
                  <StarFilled style={{ cursor: "pointer", color: "#FFB800" }} />
                  <span style={{ color: "white" }}>
                    {service.service_rating}
                  </span>
                </div>
              </div>
            ) : (
              <div className="cardRatingServiceCard">
                <div className="ratingContainer">
                  <StarFilled style={{ cursor: "pointer", color: "#FFB800" }} />
                  <span style={{ color: "white" }}>{"0"}</span>
                </div>
              </div>
            )}
            <div className="serviceInfo">
              {_.get(service, "service_desc", "") && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getShortenText(
                      _.get(service, "service_desc", ""),
                      100
                    ),
                  }}
                  className="richDesc"
                ></div>
              )}

              {(isProvider || similarBy) && service.service_fees && (
                <div className="cardPricing">
                  <p style={{ fontSize: 18, margin: 0, color: "#999" }}>BHD</p>
                  <p className="priceVal" style={{ fontSize: "18px" }}>
                    {service.service_fees}
                  </p>
                </div>
              )}
            </div>
          </Link>
        </Col>
        <Col span={2} className="postActions">
          <div className="postIconsColumn"></div>
          {/* <div className="postIconsColumn">
            <Comment />
            <Share />
          </div> */}
          <div className="authorInfo">
            <div
              style={{ cursor: "pointer" }}
              onClick={service?.issaved == false ? handleSavedItems : undefined}
            >
              {service?.issaved == false ? (
                <Save />
              ) : (
                <img src={Saved} alt="saved" />
              )}
            </div>
            <RWebShare
              data={{
                text: shareText,
                url: ` https://testing.theoctopus.ai${APPLICATION_ROUTES.SERVICE}/${service.id}`,
                title: service?.service_name,
              }}
            >
              <div className="shareIconMainPage">
                <Share />
              </div>
            </RWebShare>

            <StoryAvatar
              key={service.service_provider_details.profile_pic}
              uri={service.service_provider_details.profile_pic}
              haveStory={false}
              freelancer_id={service.provider_id}
              onClick={() => {
                // const token = localStorage.getItem("token"); // Get token from local storage
                if (token) {
                  freelancerProfile();
                  setOpenProfileDrawer(true);
                } else {
                  handleOpenLoginPopup(); // Handle case where token is not available
                }
              }}
              size={40}
              name={`${service.service_provider_details.first_name} ${service.service_provider_details.last_name}`}
              closeProfileDrawer={closeProfileDrawer}
              openProfileDrawer={openProfileDrawer}
              profileData={freelancerProfileData}
            />
            {/* )} */}
            <p
              className="subTitle"
              style={{ color: "#30263E", marginTop: "0.2rem" }}
            >
              {getShortenText(
                _.get(service?.service_provider_details, "display_name", ""),
                10
              )}
            </p>
            {/* <p className="extraText">
              {getShortenText(
                getMonthDateYear(new Date(service.created_at * 1000)),
                14
              )}
            </p> */}
          </div>
        </Col>
        {/* <LoginPopUp open={isLoginPopupOpen} onClose={handleCloseLoginPopup} /> */}
      </Row>
    );
  }
);

export default ServiceCardHome;
