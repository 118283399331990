import moment from "moment";

export const getShortenText = (name: string, length: number) => {
  if (!name) {
    return "";
  }
  return name.length > length ? name.slice(0, length - 3) + "..." : name;
};

export const getCapitalize = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const getMonthDateYear = (date1: any) => {
  const date = new Date(date1);

  return moment(date).format("MMM D, YYYY");
};

export const getAMPMTime = (date: any) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const getSelectionList = (list: any) => {
  const selectionList: any = [];
  list.forEach((item: string) => {
    selectionList.push({ value: item, label: item });
  });
  return selectionList;
};

export const getTimeOnDate = (originalTimestamp: any, newDate: any) => {
  const originalDate = new Date(originalTimestamp * 1000);
  const newDateTime = new Date(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDate(),
    originalDate.getHours(),
    originalDate.getMinutes(),
    originalDate.getSeconds()
  );

  const newTimestamp = Math.floor(newDateTime.getTime() / 1000);
  return newTimestamp;
};

export const getUnixStartOnDate = (date: any) => {
  // Set the time to 00:00:00 on the specified date
  date.setHours(0, 0, 0, 0);
  return Math.floor(date.getTime() / 1000);
};

export const getUnixEndOnDate = (date: any) => {
  // Set the time to 23:59:59 on the specified date
  date.setHours(23, 59, 59, 999);
  return Math.floor(date.getTime() / 1000);
};

export const noSpecialCharactersValidator = (
  message = "No special characters are allowed!"
) => {
  return (_: any, value: any) => {
    const regex = /^[a-zA-Z0-9\u0600-\u06FF\s]*$/;
    if (value && !regex.test(value)) {
      return Promise.reject(new Error(message));
    }
    return Promise.resolve();
  };
};

export const isValidInput = (value: any) => {
  const regex = /^[a-zA-Z0-9\u0600-\u06FF\s]*$/;
  return regex.test(value);
};

export const addressValidator = (
  message = "Invalid characters in address!"
) => {
  return (_: any, value: any) => {
    const regex = /^[a-zA-Z0-9\u0600-\u06FF\s.,'-]*$/;
    if (value && !regex.test(value)) {
      return Promise.reject(new Error(message));
    }
    return Promise.resolve();
  };
};

export const isValidAddress = (value: any) => {
  const regex = /^[a-zA-Z0-9\u0600-\u06FF\s.,'-]*$/;
  return regex.test(value);
};
